<template>
<div class="container-fluid ">


    <div class="row">

        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">

                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">

                            <h3 class="vv mb-4">Manage Beats</h3>
                        </div>
                    </div>
                </div>
                <div class="col text-right">
                    <router-link :to="'/d/beats/new'">
                        <button type="button" class="btn btn-mid btn-primary d-inline">
                            <PhPlus />
                            <span class=" pl-1 align-middle">Upload Beat</span>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="col-lg-12 bg-accent border-bottom">
            <div class="row">
                <div class="col-8">
                    <ul class="nav nav-tabs px-0 mx-0 border-0" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" @click.prevent="filterf('')">Uploaded</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="meta-tab" data-toggle="tab" href="#customize" role="tab" @click.prevent="filterf('draft')">Draft</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="meta-tab" data-toggle="tab" href="#edit" role="tab" @click.prevent="filterf('scheduled')">Scheduled</a>
                        </li>

                    </ul>
                </div>
                <div class="col d-noneb ">
                    <div class="input-group  w-25n pr-3 pull-right float-right">
                        <div class="input-group-prepend ">
                            <div class="input-group-text">
                                <PhMagnifyingGlass class="text-muted" />
                            </div>
                        </div>
                        <input type="text" class="form-control " v-model="search_text" placeholder="Search Beats...">

                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 pt-4 px-4">
            <div class="row">
                <div class="col-3">
                    <small class="text-muted">Name</small>
                </div>
                <div class="col-3">
                    <span class="text-muted">Issues</span>
                </div>
                <div class="col-3">
                    <span class="text-muted">Release Date</span>
                </div>
                <div class="col">
                    <span class="text-muted float-right mx-2">Action</span>
                </div>
                <div class="col-12">
                    <hr>
                </div>
            </div>
        </div>
        <div class="col-12 " v-show="ready">
            <ul class="list-group list-group-flush">

                <li class="list-group-item mb-0 py-2 px-0" v-for="(x,index) in fbeats" :key="x.id">
                    <div class="row">
                        <div class="col-auto px-1" style="width:40px!important;">
                            <span class="pr-3 text-muted align-middle d-flex pt-2">{{index+1}}</span>
                        </div>
                        <div class="col-auto px-0">
                            
                            <img style="height: 45px !important; width: 45px !important" :blur="0" class="radius align-middle" v-if="x.image" :src="fbase3 + x.image + '.jpg'" />
                            <Avatar class="radius align-middle" :square="true" :size="45" variant="bauhaus" v-if="!x.image" :name="x.name" :colors="colors" />
                        </div>
                        <div class="col-3 pl-1 pt-0">
                            <router-link class="tlink" :to="'/d/beats/' + x.slug">
                            <span class="px-2 text-capitalize align-top d-block"> {{ x.name }}</span>
                            
                            <span class="px-2 fnt-14 text-muted align-top d-block">{{x.sub_genre}} <span v-if="x.bpm>0">{{ " - "+x.bpm+" BPM"}}</span>  </span>
                            </router-link>
                        </div>
                        <div class="col-3">
                            <span class="tag ml-1" data-tooltip="Draft" v-if="x.draft">
                                <ph-file-dotted class="text-warning" /> <span>Draft</span>
                            </span>

                            <span class="tag ml-1" v-if="!x.stems" data-tooltip="missing stems">
                                <ph-file-x class="text-warning" /> <span>Missing Stems</span>
                            </span>
                        </div>
                        <div class="col-3">
                            <span class="text-muted small d-none d-lg-block">{{
              humandate(x.release_date)
            }}</span>
                        </div>
                        <div class="col">
                            <span class="float-right mx-2">
                                <span class="control">
                                    <button class="btn btn-secondary btn-slim" @click="deletet(x)">
                                        <ph-trash-simple class="text-danger" />
                                    </button>
                                </span>
                            </span>
                            <router-link :to="'/d/beats/' + x.slug"><span class="float-right">
                                    <span class="btn btn-secondary btn-slim">
                                        <ph-pencil-simple />
                                    </span> </span></router-link>
                            <span class="float-right mx-2">
                                <span class="control">
                                    <button class="btn btn-secondary btn-slim" @click="share(x)">
                                        <ph-share-network class="" />
                                    </button>
                                </span>
                            </span>

                        </div>
                    </div>

                </li>

            </ul>
        </div>
        <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
            <span class="loading-lg  text-center mx-auto"></span>
            <br>
            <br>
            <span class="text-muted pt-5 mt-5">loading...</span>
        </div>
    <div class="col-lg-12" v-show="ready && empty">
        <div class="row justify-content-center text-center  ">
            <div class="col-lg-12  text-center">
                <PhMusicNoteSimple class="text-primary" :size="100" />
            </div>
            <div class="col-lg-12 pt-2 text-center ">

                <p>You don't have any Beats </p>
                <router-link :to="'/d/beats/new'">
                    <button type="button" class="btn  btn-primary d-inline">
                        <PhPlus />
                        <span class=" pl-1 align-middle">Upload Beat</span>
                    </button>
                </router-link>
            </div>

        </div>
    </div>
        <div class="modal fade" id="delete_modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h5 class="modal-title text-center" id="exampleModalLabel">
                            Delete {{ todelete.name }}
                        </h5>
                        <span class="btn-close" data-dismiss="modal" aria-label="Close">
                            <ph-x />
                        </span>
                    </div>
                    <div class="modal-body">
                        <h5>Are you sure?</h5>
                        <p>Do you really want to delete <b>{{ todelete.name }}</b>, this process cannot be undone. </p>
                    </div>

                    <div class="modal-footer pb-0 px-0 mx-0">
                        <button class="btn btn-secondary" data-dismiss="modal">
                            Cancel
                        </button>
                        <button class="btn btn-danger" @click="delbeat()">Yes, Delete</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapMutations
} from 'vuex'
var jsonata = require("jsonata");
var $ = require("jquery");
var _ = require('lodash');
import Avatar from "vue2-boring-avatars";
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import moment from "moment"
import {
    PhMagnifyingGlass,
    PhFileX,
    PhX,
    PhClock,
    PhFileDotted,
    PhTrashSimple,
    PhPencilSimple,
    PhShareNetwork,
    PhPlus,
    PhMusicNoteSimple
} from "phosphor-vue";
var hdate = require('human-date')
export default {
    name: "Beats",
    components: {
        PhMagnifyingGlass,
        PhFileX,
        PhFileDotted,
        PhClock,
        PhTrashSimple,
        PhPencilSimple,
        PhShareNetwork,
        Avatar,
        PhX,
        PhPlus,
        PhMusicNoteSimple
    },
    data() {
        return {
            todelete: {
                Name: "",
            },
            beats: [],
            colors: ["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"],
            fbase3: "https://storage.googleapis.com/trillwave/image/300/",
            fbaseo: "https://storage.googleapis.com/trillwave/image/orig/",
            filter: "",
            search_text: "",
            ready: false,
            empty: false,
        };
    },
    mounted() {

        if (this.$store.state.beats == null) {

            var baseURI = this.$baseurl + "/beats";
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };
            this.$http
                .get(baseURI, {
                    headers: config,
                })
                .then((result) => {

                    //this.setBeats(result.data)
                    this.beats = result.data
                    this.ready = true
                    //this.keys = Object.keys(this.res[0]);
                });
        } else {

            this.beats = this.$store.state.beats
        }
    },
    computed: {

        fbeats() {
            var x = this.beats;
            var f = this.filter;
            var search = this.search_text
            if (x.length > 0) {
                this.empty = false
            } else {
                this.empty = true
            }
            if (f == "draft") {
                x = _.filter(x, function (o) {
                    return o.draft == true;
                });

            } else if (f == "scheduled") {
                x = _.filter(x, function (o) {
                    return moment(o.release_date) > moment();
                });

            } else {
                x = _.filter(x, function (o) {
                    return (o.draft != true && (moment(o.release_date) <= moment()));
                });

            }
            if (search != "") {
                x = _.filter(x, function (obj) {
                    return obj.name.indexOf(search) !== -1;
                });
            }
            var expression = jsonata("sub_genre");
            var result = expression.evaluate(x);

            return x;
        },
    },
    methods: {
        ...mapMutations([
            'setBeats',

        ]),
        filterf(fltr) {
            this.search_text = ""
            this.filter = fltr
        },
        humandate(stime) {

            return moment(stime).format('ll');
        },
        scheduled_date(stime) {
            return moment(stime).format('LLLL');
        },
        deletet(beat) {
            this.todelete = beat;
            $("#delete_modal").modal("show");
        },
        delbeat() {
            var baseURI = this.$baseurl + "/beats/" + this.todelete.id;
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };

            this.$http
                .delete(baseURI, {
                    headers: config
                })
                .then((result) => {
                    if (result.data.error) {
                        Snackbar.show({
                            text: result.data.message,
                            customClass: "snack-danger",
                            actionText: "DISMISS",
                        });
                        $("#delete_modal").modal("hide");
                    } else {
                        var ind = this.$store.state.beats
                            .map((e) => e.id)
                            .indexOf(result.data.id);
                        var bts = this.$store.state.beats;
                        bts.splice(ind, 1)
                        this.setBeats(bts);
                        this.beats = bts
                        Snackbar.show({
                            text: this.todelete.name + " deleted successfully",
                            customClass: "snack-success",
                            actionText: "OK",
                        });
                        $("#delete_modal").modal("hide");

                    }

                    //this.keys = Object.keys(this.res[0]);
                });
        },
    },
};
</script>

<style>
</style>
